.procedureSection {
    flex-direction: column;
    padding: 0;
    border-radius: 0.3rem;
}
.procedureSection .sectionHeader { border-radius: 0.5rem 0.5rem 0 0; }

@media print {
    body, .no-print { visibility: hidden; }
    #procedure-summary .bck-btn { display: none; }
    #procedure-summary {
        visibility: visible;
        position: absolute;
        left: 0;
        top: 0;
    }
}