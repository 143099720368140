.loginHint, .loginForm {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.hintTable {
    width: 100%;
    font-size: 14px;
}
.hintTable tr { margin-bottom: 1rem; }
.hintTable td.tabCap {
    width: 45%;
    vertical-align: baseline;
}
.hintTable td.tabCnt {
    width: 55%;
    text-align: justify;
}
.highTxt { text-decoration: underline; }
.highTxt.red { color: red; }
.highTxt.blue { color: #0070c0; }
.loginForm { justify-content: center; }
.loginForm form {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    margin: 3rem 0 2rem 0;
    width: 95%;
}
.formHeader {
    text-transform: uppercase;
    font-weight: 500;
    padding: 1rem;
    color: #fff;
    margin-bottom: 0.5rem;
    background-color: #007bff;
    border-radius: 0.25rem;
}
form .error {
    font-size: 0.8rem;
    color: red;
}