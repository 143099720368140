header {
  background-color: #f9f9f8;
  border-bottom: 1px solid #ddd;
}
header .headerBar {
  height: 3rem;
  background-color: #1f395b;
  color: #fff;
}
.sectionHeader {
  padding: 0.5rem;
  background-color: #1f395b;
  color: #fff;
}
table thead tr th { color:#007bff; }
.headerBar .headerBarActions {
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.headerActionIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: #fff;
}
.headerLogo { padding: 1.5rem 2.5rem !important; }
.siteLogo {
  height: 3.8rem;
  width: auto;
}
header a, footer a {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}
header a:hover, footer a:hover {
  text-decoration: underline;
  color: #fff;
}
.pageBody {
  background-color: #f3f6f9;
  min-height: calc(100vh - 334px);
  display: flex;
}
.pagePanel {
  background-color: #fff;
  margin: 1rem auto;
  padding: 2rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 10px #888;
  flex: 1;
}

footer {
  background-color: #132133;
  color: #fff;
}
footer .footerBar {
  height: 3rem;
  background-color: #202d40;
  color: #fff;
}
footer .footerAddr {
  padding: 1rem;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footerAddr .siteAddr {
  font-size: 14px;
  font-weight: 500;
}
.footerAddr .siteAddr p { margin: 0.25rem 0; }
.footerBar .footerBarActions {
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerBar .footerBarActions p { margin: 0; }
.actionIcon {
  width: 1rem;
  height: 1rem;
}
.actionIcon.big {
  width: 1.75rem;
  height: 1.75rem;
  fill: #1f395b
}
.btn.btn-primary {
  background-color: #1f395b;
  color: white;
  padding: 0.25rem 0.75rem;
}
.btn.btn.btn-primary svg {
  fill: white;
}